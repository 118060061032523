import { graphql } from "gatsby";
import React from "react";
import { SimpleRollPage, ReleasesRoll, ReleaseCard } from "../../components/";

const ReleasesIndexPage: React.FC<any> = ({ data }) => {
  const { edges: releases } = data.allMarkdownRemark;

  return (
    <SimpleRollPage title="Releases">
      <div className="releases gsap-list">
        {releases &&
          releases.map(({ node: release }) => (
            <ReleaseCard
              release={release.frontmatter}
              slug={release.fields.slug}
              key={release.id}
            />
          ))}
      </div>
    </SimpleRollPage>
  );
};

export default ReleasesIndexPage;

export const pageQuery = graphql`
  query AllReleasesQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "release" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            name
            author
            date(formatString: "YYYY")
            templateKey
            dsp {
              links {
                type
                url
              }
            }
            featuredimage {
              childImageSharp {
                fluid(
                  maxWidth: 200
                  quality: 100
                  srcSetBreakpoints: [100, 200]
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
